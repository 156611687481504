import { Component } from '@angular/core';
import { SvgInlineDirective } from '../../../shared/directives/svg/svg-inline.directive';
import { BreadcrumbsListComponent } from '../../../shared/components/breadcrumbs-list/breadcrumbs-list.component';

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  imports: [
    SvgInlineDirective,
    BreadcrumbsListComponent
  ],
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss'
})
export class BreadcrumbsComponent {

}
