import { Component, OnInit } from '@angular/core';
import { SvgInlineDirective } from '../../directives/svg/svg-inline.directive';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Breadcrumb } from '../../../core/data-models/bread-crumb';
import { filter, map } from 'rxjs';
import { BreadcrumbService } from '../../../core/services/breadcrumb.service';

@Component({
  selector: 'app-breadcrumbs-list',
  standalone: true,
  imports: [
    SvgInlineDirective,
    RouterModule,
    CommonModule,
    TranslateModule
  ],
  templateUrl: './breadcrumbs-list.component.html',
  styleUrl: './breadcrumbs-list.component.scss'
})
export class BreadcrumbsListComponent implements OnInit{
  breadcrumbs: Breadcrumb[] = [];
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService) {
    
  }
  ngOnInit(): void {
    // Listen to router events to rebuild the breadcrumbs on route change
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.buildBreadcrumb(this.activatedRoute.root))
      )
      .subscribe(breadcrumbs => {
        this.breadcrumbs = breadcrumbs;
        this.breadcrumbService.setBreadcrumbs(breadcrumbs);
      });

    // Build breadcrumbs on initial load
    this.breadcrumbs = this.buildBreadcrumb(this.activatedRoute.root);
    this.breadcrumbService.setBreadcrumbs(this.breadcrumbs);
  }

  private buildBreadcrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'];
      if (label) {
        breadcrumbs.push({
          label, url,
          showBackButton: false
        });
      }

      return this.buildBreadcrumb(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
