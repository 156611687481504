import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core'; // Example for ngx-translate

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    // Get the current language from your translation service or any other source
    const currentLang = this.translateService.currentLang || 'ar-SA';
    // Clone the request and add the Accept-Language header
    const modifiedReq = req.clone({
      headers: req.headers.set('Accept-Language', currentLang),
    });

    // Pass on the cloned request instead of the original request
    return next.handle(modifiedReq);
  }
}
