import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountService } from './core/services/account.service';
import { SpinnerService } from './core/services/spinner.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,
    TranslateModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Tallam.StudentsUI';
  loading$ = this.spinnerService.loading$;
  private isBrowser: boolean;
  constructor(private spinnerService: SpinnerService,
    private translate: TranslateService,
    private accountService:AccountService,
    @Inject(PLATFORM_ID) private platformId: Object) {
      this.isBrowser = isPlatformBrowser(this.platformId);
    this.translate.addLangs(['en-US', 'ar-SA']);
    this.translate.setDefaultLang('ar-SA');
    //this.translate.use('en-US');
  }
  ngOnInit(): void {
    if (this.isBrowser) {
    this.serCurrentUser();
    }
  }

  serCurrentUser(){
    var userString=localStorage.getItem("student");
    //decrypt
     if( !userString)return
     let user=JSON.parse(userString);
     this.accountService.setCurrentUser(user);
   }
}
