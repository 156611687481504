import { Injectable } from '@angular/core';
import { SpinnerService } from './spinner.service';
@Injectable({
  providedIn: 'root'
})
export class BusyService {

  busyRequestCounter=0;
  constructor(private spinnerService: SpinnerService) { }
  busy(){
    this.busyRequestCounter++;
    this.spinnerService.show();
  }
  
  idle(){
      this.busyRequestCounter--;
      if(this.busyRequestCounter<=0)
      this.busyRequestCounter=0;
    this.spinnerService.hide();
  }
}
