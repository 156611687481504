<div class="tm-breadcrumbs">
    <h3 class="page-title">
        {{ breadcrumbs[breadcrumbs.length - 1]!.label | translate }}
    </h3>
    <ul class="breadcrumbs-list">
      <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
        <a *ngIf="!last" [routerLink]="breadcrumb.url">{{ breadcrumb.label | translate }}</a>
        <span *ngIf="last" class="active">{{ breadcrumb.label | translate }}</span>
        <span *ngIf="!last" class="svg-icon" [svgIcon]="'chevDown'"></span>
      </li>
    </ul>
  </div>
  