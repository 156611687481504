<footer class="tm-footer-wrapper">
    <span class="footer-decoration"></span>
    <div class="container">
        <div class="tm-footer">
            <div class="footer-content">
                <div class="row">
                    <div class="col-md-5">
                        <div class="footer-logo">
                            <span class="svg-icon" [svgIcon]="'logoMain'"></span>
                        </div>
                        <p class="footer-desc">منصتنا تتميز بتقديم تجربة تعلم شاملة، حيث نقدم برامج تعليمية متميزة تشمل دورات متخصصة وموارد تعليمية غنية.</p>
                        <ul class="footer-social">
                            <li>
                                <a href="" class="btn tm-btn btn-transparent">
                                    <span class="svg-icon" [svgIcon]="'twitter'"></span>
                                </a>
                            </li>
                            <li>
                                <a href="" class="btn tm-btn btn-transparent">
                                    <span class="svg-icon" [svgIcon]="'facebook'"></span>
                                </a>
                            </li>
                            <li>
                                <a href="" class="btn tm-btn btn-transparent">
                                    <span class="svg-icon" [svgIcon]="'linkedin'"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5">
                        <h3 class="footer-title">روابط مفيده</h3>
                        <ul class="footer-links">
                            <li>
                                <a href="" class="btn tm-btn btn-transparent">الرئيسية</a>
                            </li>
                            <li>
                                <a  routerLink="/courses" class="btn tm-btn btn-transparent">المواد الدراسية</a>
                            </li>
                            <li>
                                <a href="" class="btn tm-btn btn-transparent">تواصل معنا</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3 class="footer-title">وسائل الدفع</h3>
                        <ul class="footer-payment-methods">
                            <li>
                                <span class="svg-icon" [svgIcon]="'mastercard'"></span>
                            </li>
                            <li>
                                <span class="svg-icon" [svgIcon]="'visa'"></span>
                            </li>
                            <li>
                                <span class="svg-icon" [svgIcon]="'applepay'"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer-copyrights">
                <p>
                    جميع الحقوق محفوظة
                    <a href=""> &#64; منصة تعلم</a>
                </p>
                <ul class="copyrights-links">
                    <li>
                        <a href="">شروط الإستخدام</a>
                    </li>
                    <li>
                        <a href="">سياسة الخصوصية</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>