import { Component, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { SvgInlineDirective } from '../../../shared/directives/svg/svg-inline.directive';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '../../../core/services/account.service';
import { Router, RouterModule } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    SvgInlineDirective,
    NgbDropdownModule,
    RouterModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  currentLang: 'ar' | 'en' = 'ar';
  isRtl: boolean = true;
  baseUrl = environment.apiUrl;
  constructor(@Inject(DOCUMENT) private document: Document,
  public accountService:AccountService,
  private router:Router,
  private authService: SocialAuthService) {}

  ngOnInit(): void {
    this.setLanguageDirection();
  }

  toggleLanguage(): void {
    this.isRtl = !this.isRtl;
    this.currentLang = this.isRtl ? 'ar' : 'en';
    this.setLanguageDirection();
  }

  private setLanguageDirection(): void {
    const body = this.document.body;
    const html = this.document.documentElement;

    if (this.isRtl) {
      body.classList.remove('ltr');
      body.classList.add('rtl');
      html.dir = 'rtl';
      html.lang = 'ar-SA';
    } else {
      body.classList.remove('rtl');
      body.classList.add('ltr');
      html.dir = 'ltr';
      html.lang = 'en-US';
    }
  }


  logout(){
    this.accountService.logout(); 
    this.authService.signOut();
    window.location.href = '/login';

  }
  goToChangePassword(){
    this.router.navigateByUrl("/change-password");
  }


  
}
