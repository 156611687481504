import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

export const routes: Routes = [
    {
      path: '',
      component: MainLayoutComponent,
      data: { breadcrumb: 'الرئيسية',showBreadcrumb: true }, // Main layout breadcrumb
      children: [
        { 
          path: '', 
          redirectTo: 'home', 
          pathMatch: 'full' 
        },
        {
          path: 'courses',
           data: { breadcrumb: 'المواد الدراسية',showBreadcrumb: true },
          children: [
            {
              path: '',
              data: { breadcrumb: null },
              loadComponent: () =>
                import('./features/courses-list/courses-list.component').then(m => m.CoursesListComponent),
            },
            {
              path: 'course-details/:courseId',
              data: { breadcrumb: null, showBreadcrumb: false },
              loadComponent: () =>
                import('./features/courses-list/course-details/course-details.component').then(
                  m => m.CourseDetailsComponent
                )
            }
          ]
        },
        {
          path: 'home',
          loadComponent: () =>
            import('./features/home/home.component').then(m => m.HomeComponent),
          data: { breadcrumb: null ,showBreadcrumb: false }
        },
        {
          path: 'subscription',
          data: { breadcrumb: 'الإشتراك',showBreadcrumb: true },
          children: [
            {
              path: '',
              data: { breadcrumb: null },
              loadComponent: () =>
                import('./features/subscription/subscribe/subscribe.component').then(m => m.SubscribeComponent),
            },
            {
              path: 'course-list',
              data: { breadcrumb: 'المواد الدراسية',showBreadcrumb: true },
              loadComponent: () =>
                import('./features/subscription/show-courses-list/show-courses-list.component').then(
                  m => m.ShowCoursesListComponent
                )
            }
          ]
        },
        {
          path: 'payment-confirmation',
          loadComponent: () =>
            import('./features/subscription/payment-confirmation/payment-confirmation.component').then(m => m.PaymentConfirmationComponent),
          data: { breadcrumb: null ,showBreadcrumb: false }
        },
        {
          path: 'login',
          data: { breadcrumb: 'تسجيل الدخول',showBreadcrumb: true },
          children: [
            {
              path: '',
              data: { breadcrumb: null },
              loadComponent: () =>
                import('./features/authentication/login/login.component').then(
                  m => m.LoginComponent
                )
            },
            {
              path: 'forget-password',
              loadComponent: () =>
                import('./features/authentication/forget-password/forget-password.component').then(
                  m => m.ResetPasswordComponent
                ),
              data: { breadcrumb: 'إستعادة كلمة المرور',showBreadcrumb: true }
            },
            {
              path: 'reset-password',
              loadComponent: () =>
                import('./features/authentication/reset-password/reset-password.component').then(
                  m => m.resetPasswordComponent
                ),
              data: { breadcrumb: 'تغيير كلمة المرور' , showBreadcrumb: true }
            },
            {
              path: 'email-sent',
              loadComponent: () =>
                import('./features/authentication/email-sent/email-sent.component').then(
                  m => m.EmailSentComponent
                ),
              data: { breadcrumb: 'إستعادة كلمة المرور',showBreadcrumb: true  }
            },
          ]
        },
        {
          path: 'register',
          loadComponent: () =>
            import('./features/authentication/register/register.component').then(
              m => m.RegisterComponent
            ),
          data: { breadcrumb: 'تسجيل جديد',showBreadcrumb: true }
        },
        {
          
          path: 'change-password',
          loadComponent: () =>
            import('./features/authentication/change-password/change-password.component').then(
              m => m.ChangePasswordComponent
            ),
          data: { breadcrumb: 'تغيير كلمة المرور' , showBreadcrumb: true }
        },
        {
          path: 'template',
          loadComponent: () =>
            import('./template/template.component').then(m => m.TemplateComponent),
          data: { breadcrumb: 'القالب',showBreadcrumb: true }
        },
        {
          path: 'personal-account',
          data: { breadcrumb: 'الحساب الشخصي',showBreadcrumb: true },
          children: [
            {
              path: '',
              data: { breadcrumb: null },
              loadComponent: () =>
                import('./features/personal-account/personal-account.component').then(m => m.PersonalAccountComponent)
            },
            {
              path: 'edit',
              data: { breadcrumb: null },
              loadComponent: () =>
                import('./features/personal-account/edit-my-account/edit-my-account.component').then(m => m.EditMyAccountComponent)
            },
            {
              path: 'course-list-details',
              loadComponent: () =>
                import('./features/course-list-details/course-list-details.component').then(
                  m => m.CourseListDetailsComponent
                ),
              data: { breadcrumb: 'التفاصيل',showBreadcrumb: true }
            },
          ]
         
        },
        {
          path: 'view-course-content/:contentId',
          loadComponent: () =>
            import('./features/view-course-content/view-course-content.component').then(m => m.ViewCourseContentComponent),
          data: { breadcrumb: null,showBreadcrumb: false }
        },
      ]
    }
  ];
  
