import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, delay, finalize } from 'rxjs';
import { BusyService } from '../services/busy.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoaingInterceptor implements HttpInterceptor {
 

  constructor(private busyService:BusyService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
     // List of URLs to exclude from the interceptor
     const excludedUrls = ['vimeo.com',
      environment.apiUrl+'api/Students/GetStudentsThatCanSubscribe',
      environment.apiUrl+'api/Contents/ChangeOrder'];

     // Check if the request URL contains any of the excluded URLs
     const shouldExclude = excludedUrls.some(url => request.url.includes(url));
 
     if (shouldExclude) {
       // If the URL matches an excluded URL, proceed without modifying the headers
       return next.handle(request);
     }
    this.busyService.busy();
    return next.handle(request).pipe(
      delay(500),
      finalize(()=>{
        this.busyService.idle()
      })
    );
  }
}
