import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private route:Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error:HttpErrorResponse)=>{
        if(error){
          switch(error.status){
            
            case 400:
              if(error.error.errors){
                const modelStateErrors=[];
                for(const key in error.error.errors){
                  if(error.error.errors[key]){
                    modelStateErrors.push(error.error.errors[key]);
                  }
                }
                throw modelStateErrors.flat();
              }else{
               // this.customMessageService.showMessage('error', error.error, error.status.toString());
              }
              break;
            case 419:
              if(error.error){
                const modelStateErrors=[];
                for(const key in error.error){
                  if(error.error[key]){
                    modelStateErrors.push(error.error[key]);
                  }
                }
                const [, messages] = modelStateErrors;
                messages.forEach((element:any) => {
                 // this.customMessageService.showMessage('error', 'LOGIN.VALIDATION_ERROR', element.Message);
                });
                throw modelStateErrors.flat();
              }
              break;
              case 401:
                this.route.navigateByUrl("");
            //   this.customMessageService.showMessage('error', 'LOGIN.NOT_AUTHORIZED', 'LOGIN.NOT_AUTHORIZED_Message');
              break;
              case 404:
                //this.route.navigateByUrl("/not-found");
                break;
              case 418:
              // this.customMessageService.showMessage('error', 'LOGIN.ERROR_OCCURRED', error.error.Message);
                break;
              case 500:
                const navigationExtras:NavigationExtras={state:{error:error.error}}
                this.route.navigateByUrl("/server-error",navigationExtras);
                break;

          }
        }
        throw error;
      }
      )
    )
  }
}
