<div class="tm-breadcrumbs-wrapper">
    <span class="svg-icon bg-logo-vector-start" [svgIcon]="'logoVector'"></span>
    <span class="svg-icon bg-logo-vector-end" [svgIcon]="'logoVector'"></span>
    <span class="svg-icon bg-logo-vector-blur-start" [svgIcon]="'logoVectorBlur1'"></span>
    <span class="svg-icon bg-logo-vector-blur-end" [svgIcon]="'logoVectorBlur2'"></span>
    <img class="bell-vector" src="/assets/media/vectors/bell.png" alt="bell vector" draggable="false">
    <img class="ring-vector" src="/assets/media/vectors/ring.png" alt="ring vector" draggable="false">
    <img class="cone-vector" src="/assets/media/vectors/cone.png" alt="cone vector" draggable="false">
    <img class="chair-vector" src="/assets/media/vectors/chair.png" alt="chair vector" draggable="false">
    <!-- <div class="tm-breadcrumbs">
        <h3 class="page-title">تسجيل الدخول</h3>
        <ul class="breadcrumbs-list">
            <li>
                <a href="/">الرئيسية</a>
            </li>
            <li>
                <span class="svg-icon" [svgIcon]="'chevDown'"></span>
                <a href="" class="active">تسجيل الدخول</a>
            </li>
        </ul>
    </div> -->
    <app-breadcrumbs-list></app-breadcrumbs-list>
</div>
