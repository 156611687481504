<header class="tm-header-wrapper">
    <div class="container">
        <div class="tm-header">
            <div class="header-nav">
                <div class="header-logo">
                    <span class="svg-icon" [svgIcon]="'logoMain'"></span>
                </div>
                <ul class="menu-list">
                    <li>
                        <a routerLink="/home" routerLinkActive="active">الرئيسية</a>
                    </li>
                    <li>
                        <a  routerLink="/courses" routerLinkActive="active">المواد الدراسية</a>
                    </li>
                    <li>
                        <a  routerLink="/subscription" routerLinkActive="active">إشترك الآن</a>
                    </li>
                    <li>
                        <a   routerLinkActive="active">تواصل معنا</a>
                    </li>
                </ul>
            </div>
            <div class="header-toolbar">
                <!-- Check if user is logged in -->
                <ng-container *ngIf="(accountService.currentUser$ | async) as user; else guest">
                <!-- User Profile Section -->
                <label class="header-user">
                    <div class="user-img-wrapper">
                        <img *ngIf="user.imagePathUrl" [src]="baseUrl + user.imagePathUrl" alt="User Image">
                        <img *ngIf="!user.imagePathUrl" src="/assets/media/user-default.png" alt="User Image">
                    </div>
                    <h3>{{ user.fullName || 'اسم المشترك' }}</h3>
                    <div class="dropdown user-dropdown" ngbDropdown>
                        <button type="button" class="btn tm-btn btn-link text-body btn-dropdown" ngbDropdownToggle>
                            <span class="svg-icon" [svgIcon]="'chevDown'"></span>
                        </button>
                        <div ngbDropdownMenu class="mt-10" >
                            <button 
                                ngbDropdownItem 
                                type="button" 
                                class="btn tm-btn btn-link fs-sm btn-dropdown"
                                routerLink="/personal-account"
                                >
                                <span class="svg-icon" [svgIcon]="'user'"></span>
                                الملف الشخصى 
                            </button>
                            <button *ngIf="!user.isGoogleUser"
                                ngbDropdownItem 
                                type="button" 
                                class="btn tm-btn btn-link fs-sm btn-dropdown"
                                (click)="goToChangePassword()">
                                <span class="svg-icon" [svgIcon]="'gear'"></span>
                                تغيير كلمة المرور
                            </button>
                            <div class="dropdown-footer">
                                <button 
                                    ngbDropdownItem 
                                    type="button" 
                                    class="btn tm-btn btn-link fs-sm btn-dropdown"
                                    (click)="logout()">
                                    <span class="svg-icon" [svgIcon]="'logout'"></span>
                                    تسجيل الخروج
                                </button>
                            </div>
                        </div>
                    </div>
                </label>
                </ng-container>

                <!-- Guest Section -->
                <ng-template #guest>
                    <button 
                        type="button" 
                        class="btn tm-btn btn-primary-gradient" 
                        onclick="window.location.href='/login';">
                        تسجيل دخول
                    </button>
                    <button 
                        type="button" 
                        class="btn tm-btn btn-primary btn-outline" 
                        onclick="window.location.href='/register';">
                        حساب جديد
                    </button>
                </ng-template>

                <button type="button" class="btn tm-btn btn-language" (click)="toggleLanguage();">
                    <span class="svg-icon" [svgIcon]="'global'"></span>
                    English
                </button>
            </div>
        </div>
    </div>
</header>