import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaingInterceptor } from './core/interceptors/loading.interceptor';
import { provideHighlightOptions } from 'ngx-highlightjs';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageInterceptor } from './core/interceptors/language.interceptor';
// Import the social login module and providers
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const appConfig: ApplicationConfig = {
  providers: [
     provideRouter(routes),
     provideClientHydration(),
     provideHttpClient(withFetch()),
     provideHttpClient(withInterceptorsFromDi()),  
     [ 
      {provide:HTTP_INTERCEPTORS,useClass:ErrorInterceptor,multi:true},
      {provide:HTTP_INTERCEPTORS,useClass:JwtInterceptor,multi:true},
      {provide:HTTP_INTERCEPTORS,useClass:LoaingInterceptor,multi:true},
      {provide:HTTP_INTERCEPTORS,useClass:LanguageInterceptor,multi:true},
    ],
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      BrowserAnimationsModule,
      SocialLoginModule
    
  ]),
    provideHighlightOptions({
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'), // Optional, add line numbers if needed
      languages: {
        typescript: () => import('highlight.js/lib/languages/typescript'),
        css: () => import('highlight.js/lib/languages/css'),
        xml: () => import('highlight.js/lib/languages/xml')
      },
    }),
    // Provide SocialAuthServiceConfig
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, // Auto-login after redirect
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
            '1024557920015-mbaak646cks4j0rovgeqidpine595duu.apps.googleusercontent.com',
            {
              scopes: 'email profile https://www.googleapis.com/auth/user.phonenumbers.read'
            } // Replace with your Google Client ID
          )
          }
        ],
        onError: (err) => {
          console.error('Social login error:', err);
        }
      } as SocialAuthServiceConfig,
    }

    ],
    
};