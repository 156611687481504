import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent
],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent  implements OnInit{
  isHome: boolean = false;
  showBreadcrumb: boolean = true;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
      // Check breadcrumb visibility on navigation end
      this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.updateBreadcrumbVisibility());

    // Also handle initial load (in case of reload)
    this.updateBreadcrumbVisibility();
  }

  private updateBreadcrumbVisibility(): void {
    const route = this.getDeepestChildRoute(this.activatedRoute);
    this.showBreadcrumb = route.snapshot.data['showBreadcrumb'] ?? true;
  }

  // Helper to find the deepest child route
  private getDeepestChildRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }


  // checkIfHome() {
  //   // Get the URL path
  //   const urlPath = this.router.url.split('/')[1]; // This will exclude the domain
    
  //   // Check if the path is 'home'
  //   this.isHome = (urlPath === 'home');
  // }
}
