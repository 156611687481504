import { Component } from '@angular/core';
import { SvgInlineDirective } from '../../../shared/directives/svg/svg-inline.directive';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    SvgInlineDirective,
    RouterModule
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

}
