import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb } from '../data-models/bread-crumb';



@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbSubject.asObservable();
  private breadcrumbs: Breadcrumb[] = [];
  constructor(private translate: TranslateService) {}

  async setBreadcrumbs(breadcrumbs: Breadcrumb[]): Promise<void> {
    this.breadcrumbs = await this.translateBreadcrumbs(breadcrumbs);
    this.breadcrumbSubject.next(this.breadcrumbs);
  }

  getCurrentBreadcrumbs(): Breadcrumb[] {
    return this.breadcrumbs;
  }

  async translateBreadcrumbsOnLangChange(): Promise<void> {
    this.breadcrumbs = await this.translateBreadcrumbs(this.breadcrumbs);
    this.breadcrumbSubject.next(this.breadcrumbs);
  }

  private async translateBreadcrumbs(breadcrumbs: Breadcrumb[]): Promise<Breadcrumb[]> {
    const translatedBreadcrumbs: Breadcrumb[] = [];
    for (const breadcrumb of breadcrumbs) {
      const translatedLabel = await this.translate.get(breadcrumb.label).toPromise();
      translatedBreadcrumbs.push({
        ...breadcrumb,
        label: translatedLabel
      });
    }
    return translatedBreadcrumbs;
  }
}
