<!-- main-layout.component.html -->
<!-- <app-header></app-header>
<div class="main-content">
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer></app-footer> -->

<div class="flex-root">
  <div class="flex-column flex-row-fluid">
      <div class="content flex-column flex-column-fluid">
          <!-- Header -->
          <app-header></app-header>
          <!-- end: Header -->
           
          <!-- Content -->
          <div class="flex-column-fluid wrapper">
              <div class="container-fluid" >
                <ng-container *ngIf="showBreadcrumb">
                  <app-breadcrumbs></app-breadcrumbs>
                </ng-container>
                  <router-outlet></router-outlet>
              </div>
          </div>
          <!-- end: Content -->

          <!-- Footer -->
          <app-footer></app-footer>
          <!-- end: Footer -->
      </div>
  </div>

  <!-- spinner -->
  <!-- <app-spinner></app-spinner> -->
  <!-- end: spinner -->
  <!-- scrolltop -->
  <!-- <app-scrolltop></app-scrolltop> -->
  <!-- end: scrolltop -->
</div>